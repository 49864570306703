.audio-player-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5; /* Change to your preferred background color */
  background-image: url(../src/assets/A563A73A-FC2C-4AB3-A012-409609E965CB\ \(2\).jpeg);
  background-repeat: repeat;
}
span{
  color: #fff;
}
.button-star>span
{
  /* font-size: 122px; */
  /* margin: 0 10px; */
}
.audio-player-container {
  background-color: #000;
  align-self: center;
  box-shadow: 1px 3px 20px 8px rgba(0, 0, 0, .09);
  /* height: inherit; */
  padding: 20px;
  border-radius: 12px;
  /* background-image: url(../src/assets/ezgif.com-animated-gif-maker.gif); */

  /* height: 667px; */
  /* overflow: hidden; */
  justify-content: center;
  width: 600px;
  min-width: 400px;
  /* flex-direction: column; */
  position: relative;
}
@keyframes changeColor {
  0% { color: #d7ff69; } /* Starting color */
  50% { color: #c5cd3f; } /* Mid color */
  100% { color: #fcfcfc; } /* Ending color */
}

.auto-color {
  animation: changeColor 5s infinite; /* Change color every 5 seconds */
  font-weight: bold;
  font-size: 44px; /* Adjust font size as needed */
  font-family: cursive;
  font-style: italic;
}
.gradient-text {
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 24px; /* Adjust font size as needed */
}
.media-button {
  align-items: center;
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  border: 0;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  justify-content: center;
  box-shadow: -1px 17px 24px -6px rgba(0, 0, 0, .2);
  pointer-events: none;
  display: none;
  cursor: pointer;
  transition: box-shadow .1s, -webkit-transform .1s;
  transition: box-shadow .1s, transform .1s;
  transition: box-shadow .1s, transform .1s, -webkit-transform .1s;
  position: relative;
}

.app-logo {
  width: 300px; /* Adjust the logo size as needed */
  margin-bottom: 20px;
  border-radius: 12px;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

button {
  background-color: #1890ff; /* Change to your preferred button color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.mt-1{
  margin-top: 3px;
}
button:hover {
  background-color: #40a9ff; /* Change to your preferred hover color */
}
.text-mute{
  color: #999;
}